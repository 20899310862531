
.footer{
    position: static;
margin-top: 700px;
    bottom: 0;
    background: linear-gradient(268.59deg, #f698ff, #696eff 93.98%);
    width: 100%;
    height: 0px;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content:center;
  
  
  }
  
  
  .footer span{
  
  
    font-weight: 300;
    color: #ffffff;
    
  }
  
  .footer img{
    position: absolute;
    margin-top: -60px;
  }
  
  .bass{
    background-color: #161616;
    height: 66px;
  }