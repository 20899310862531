.containerBox{
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #000000;
}
.boxinfo{

display: flex;
margin-top: -100px;
width: 360px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafafc;
  margin-left: 16px;
  margin-right: 16px;
border-radius: 20px 20px 0px 0px;
  height: 1000px;
  border-left: 1px solid #fafafc;
  border-right: 1px solid #fafafc;

  
}




.blsss{
  height: 13px;
  width: 13px;
  margin-top: 25px;
  margin-left: -20px;

 

  border-radius: 11px;
  background-color: rgb(4, 255, 79);
  animation: blss .99s linear infinite;
  box-shadow: 33px #00ff40;
}
.bx1{
 
  display: flex;
  background-color: rgba(0, 0, 0, 0.103);
  width: 360px;
  height: 100px;

 


}

.itemsposts{

  display: flex;
  flex-direction: column;
  width: 200px;

}

.itemsposts span{
  color: #0202026e;
}
.bx2{
  display: flex;
  background-color: #161616;
  width: 233px;
  height: 63px;
 border-radius: 8px;
 margin-bottom: 10px;
}
.bx3{
  display: flex;
  background-color: #161616;
  width: 233px;
  height: 63px;
 border-radius: 8px;
}
.bra{
  margin-left: 33px;
}

.bc{
  visibility: visible;
  
}
.bl{
  
  visibility: hidden;
}
.bt{
  visibility: visible;
}
.blk{
  animation: blk 1s steps(1, end) infinite;
}
@keyframes blk{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }

}
.md{
  width: 100%;
  background-color: #FFF;
  height: 100%;
}

.br1{
  margin-top: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffffd9;
box-shadow:0px 0px 8px 2px #bababae2;
border-radius: 33px;
height: auto;
  margin-left: 10px;
  margin-right: 10px;
}
.btna{
  visibility: visible;
  margin-top: 33px;
  margin-bottom: 19px;
 justify-content: center;
 border-radius: 10px;
  display: flex;
  align-items: center;
 background-color: rgb(0, 171, 46);
 color: #FFF;
 width:300px;
 height: 60px;
 font-weight: bold;
 font-size: 23px;
}
.abtn{
  visibility: hidden;
 
}

.separator{
  margin-top: 50px;
}

.boxinfo input{

 color: #1d1d1f;
 height: 53px;
border-style: none;
list-style: none;

flex-grow: 1;
font-size: 16px;
font-weight: 500;
height: 100%;
line-height: 1.25;
width: 130px;

background: transparent;
outline: none;
text-align: flex-start;



}

.boxinfo input::placeholder{

  color: #1d1d1f;
}


.boxinfo span{
  margin-top: 20px;

  color: rgb(118, 118, 118);
}

.boxinfo h2{
  background-color: rgba(255, 255, 255, 0.103);
  color: #02020283;
  width: 360px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.boxinfo a{
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
  margin-top: 20px;
 
  background: #147a58;

  width: 300px;
  height: 60px;
  border-radius: 60px;


}

h1{
  margin-top: 30px;
  text-align: center;
  font-size: inherit;
  font-weight: bold;
  font-size: 16px;
}
.mages{
 display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: -34px;
 
 
}
.bx1 img{
  margin-left: 10px;
}
.bx1 span{
  color: #0202026e;
  font-weight: 300;
}
.bx1 p{
  color: #02020283;
  font-weight: 500;
}
.bx2 span{
  color: #FFF;
  font-weight: bold;
}
.bx3 span{
  color: #FFF;
  font-weight: bold;
}


.mages img{
 padding: 11px;
 
  justify-content: center;
  border-radius: 50%;
  height: 43px;

}
.boxicones{

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  
 
 

}



.boxiconesgeral{
flex-direction: column;
align-items: center;
text-align: center;
justify-content: center;
width: 70px;

  
}

.boxiconesgeral img{
margin-left: 30px;
margin-right: 50px;
  width:15;

}

.boxiconesgeral span{
  margin-left: 20px;
margin-right: 50px;
font-size: 10px;

}

.bxdate{
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.bxdate h4{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #1d1d1f;
  font-family: Roboto;
  width: 300px;
  border-radius: 50px;
  height: 50px;
}
.ptexto{
  margin-bottom: 30px;
  color: #1d1d1f;
  font-family: Roboto;
  font-weight: bold;
  margin-left: 35px;
}
.bxdate span{
  font-size: 20px;
 
}