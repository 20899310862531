.Principal{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    margin-top: -100px;
}

.contCelular{
    background-color: rgba(0, 0, 0, 0.904);
}

 .link{
    background-color: rgb(2, 159, 83);
margin-top: 10px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;

}

.link span{
    
    width: 350px;
    height:100px;
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size:20px;
    justify-content: center;
}

.divInvest{
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.904);
height: 100px;
width: 350px;

}
.divInvest span{
    color: white;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
}
.boxmens{
    background-color: rgba(0, 0, 0, 0.904);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3px;
   
  
   
}
.mensagens img{
    border-radius: 10px;
    filter: blur(12px);
   
}

.boxmens h3{
    background-color: rgb(255, 255, 255);
    width: 350px;
   text-align: center;
   font-size: 15px;
  
}
.ftcontainer{
    background-color: rgba(0, 0, 0, 0.904);
    width: 350px;
    text-align: center;
}
.fotos{
    background-color: rgb(2, 2, 2);
    width: 350px;
    align-items: center;
    display: flex;
    justify-content: center;
   
}
.fotos img{
    margin-top: 20px;
    border-radius: 10px;
    filter: blur(8px);   
}
.mensagens{
  
  display: flex;
  
    height: 100px;
   align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.904);
  
    width: 350px;
  
   
}

.mensagens span{
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
    color: #FFF;
}