.ctChat{
  
    display: flex;
    align-items: center;
    justify-content: center;
  

}

.bxicons{
 
   left: 0;
    width: 70px;
    height: 956px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}

.icons{
    display: flex;
    align-items: center;
    justify-content: center;

 width: auto;
    margin-top: 10px;
  filter: grayscale(6.0) ;
}
.iconss{
    display: flex;
    align-items: center;
    justify-content: center;

 width: 100%;
    margin-top: 10px;

}

.iconss button{
 display: flex;
 align-items: center;
 justify-content: center;
 height: 50px;
    border: none;
    background-color: #f0f2f5;
}

.iconss button:hover{
   
    width: 100%;
    background-color: rgba(181, 181, 190, 0.342);
}

.iconss button img{
    border-radius: 50%;
}

.icons button{
    border: none;
    background-color: #f0f2f5;
}

.icons button:hover{
    background-color: rgba(181, 181, 190, 0.342);
}

.divcont{
    height: 530px;
}
.bxchat{
   
    width: 337px;
    height: auto;
    background-color: #FFF;
    align-items: center;
   
    display: flex;
    flex-direction: column;
}
.bxespaco{
    width: 10px;
    height: 100%;
    background-color: #f0f2f5;
}

.headerchat{
   
    width: 330px;
    display: flex;
    border: none;
    
    
}

.headerchat span{
    margin-left: 30px;
    width:180px;
    font-weight: 900;
    font-size: 20px;
}

.btnliberar{
    border: none;
    border-radius: 10px;
    color: #FFF;
   width: 200px;
   height: 40px;
display: flex;
justify-content: center;
align-items: center;
margin-right: 100px;
margin-left: 10px;

   background-color: #25D366;
}

.iconsheader{
    display: flex;
    align-items: center;
    justify-content: center;

 width: 100%;
    margin-top: 10px;
  filter: grayscale(6.0) ;
}

.iconsheader button{
    border: none;
background-color: #FFF;
}
.contseach{
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: #f0f2f5;
    width: 300px;
    height: 40px;
  
}

.seachicons{
    margin-left: 10px;
}


.textcont{
    display: flex;
    align-items: center;
    background-color: #ffffff;
    justify-content: left;
    margin-top: 10px;
    width: 300px;
    margin-left: 10px;
}

.texticons{
 

    border-radius: 10px;
    height: 30px;
   padding: 2px;
 

    
}

.textcont button span{
    font-weight: 500;
}

.texticons button{
   
    border-radius: 10px;
    height: 30px;
    border: none;
}


.texticons button:hover{
   
    background-color: #21b1757a;
}

.listtodos{
   
    top: 0;
    background-color: #FFF;
    margin-top: 20px;
    width: 300px;
    overflow: scroll;
   height: 760px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
   

}

.lista{
   
    margin-top: 10px;
    display: flex;
    align-items: center;

    flex-direction: column;

   
    border-top: none;
    border-left: none;
    border-right: none;
    
}




.bxlistacont{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 300px;
    height: 50px;
   
   
}
.bxlistacont img{
    border-radius: 50%;
    filter: brightness(0.6) blur(1px);
  
}
.bxlist{
    display: flex;
    flex-direction: column;
}

.iconslist{
    margin-left: 10px;
    display: flex;
    
}

.sepli{
    width:70px;
   
}
.iconslisthora span{
    font-size: 10px;
}

.bxverd{
    display: flex;
    flex-direction: column;
}
.bxverdehideen{
    display: none;
}

.bxverde{
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: center;
    border-radius: 50%;
width: 20px;
height: 20px;
    background-color: #25D366;
}
.bxverde span{
    color: #FFF;
    font-size: 10px;
}

.bxverde p{
    width: 20px;
    height: 20px;
   background-color: #FFF;

}

.lista button{
    border: none;
    background-color: #FFF;
}

.lista button:hover{
    background-color: #f0f2f5;
}

.menusubchat{
   

    display: flex;
   
    align-items: center;
    justify-content: center;
width: 340px;
    height: 80px;
  
 background-color: #0f0f0f1a;
 


}
.menusubchat button{
   color: #000000;
   background-color: #21b17500;
  font-size: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menusubchat span{
    margin-left: 10px;
}

.menusubchat a{
    width: 80%;
   
}

.menusubchat img{
    margin-left: 5px;
    border-radius: 50%;
    width: 40px;
    filter: brightness(0.6) blur(1px);
   
}

.listitem{
   
    background-color: #FFF;
   
    width: 340px;
  
   height: 960px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
}

.listchats{
 
    background-color: #57565642;
    width: 100%;
    height: 800px;
    overflow: scroll;
}
.microicon{
    background-color: #25D366;
    width: 45px;
    height: 35px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.bootmlistitem{
    background-color: #0f0f0f1a;
    width: 100%;
    height: 80px;
display: flex;
align-items: center;
justify-content: center;
   
}

.fxbot{
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}

.divultimas span{
    text-align: center;
    font-size: .75rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    line-height: 1.2em;
    overflow: hidden;
    position: relative;
    margin: 1.5625rem 0;
}

.botchat{
    display: flex;
    align-items: center;
    justify-content: center;
}

.botcont{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.boxchatbot{
    background: #D9FDD3;
    color: #263238;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    border-radius: .8rem .8rem 0 .8rem;
    position: relative;
    padding: 1rem;
    font-weight: 500;
  width: 200px;
}

.boxchatbot1{
    margin-left: 10px;
    background: #ffffff;
    color: #263238;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    border-radius: .8rem .8rem 0 .8rem;
    position: relative;
    padding: 1rem;
    font-weight: 500;
    width: 200px;
}
.bxicon2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.botcont span{
    margin-top: 10px;
margin-left: 150px;
}
.icotres{

  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 10px;
}

.botcont2{
    display: flex;
   
}
.box2cont span{
    margin-top: 10px;
    margin-left: 10px;
}

.imgbotcont2{
    
    display: flex;
    align-items: flex-end;
    margin: .3125rem 0;
    font-size: .9375rem;
}

.imgbotcont2 img{
    margin-left: 5px;
    border-radius: 50%;
    width: 40px;
    filter: brightness(0.6) blur(1px);
}

.box2cont{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.bootmlistitem{
 
    display: flex;

 justify-content: center;
}

.ico{
    color: #b8b8b8;
    background-color: #ffffff;
    flex: 1 1 auto;
    position: relative;
    padding-left: 1rem;
    margin-right: 1rem;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
  border-end-end-radius: 5px;
  border-start-end-radius: 5px;
 
 

}

.form-control{
    margin-left: 5px;
    border: none;
    box-shadow: 0 0 0 0;
    outline: 0;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
   
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}