.Principal{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

 .link{
    background-color: rgb(2, 159, 83);
margin-top: 10px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;

}

.link span{
    
    width: 350px;
    height:100px;
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size:20px;
    justify-content: center;
}

.divInvest{
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
background-color: rgb(2, 159, 83);
height: 100px;
width: 350px;

}
.divInvest span{
    color: white;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
}
.boxmenss{
   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -50px;
  
   
  
   
}
.mens{
    display: flex;
    border-radius: 10px;
    margin-top: 10px;
      height:200px;
     align-items: center;
      justify-content: center;
      background-color: white;
      flex-direction: column;
    
      width: 350px;
}
.mens h1{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    font-weight: inherit;
}
.mensa{
  
 display: flex;
  border-radius: 10px;
  margin-top: 10px;
    height: 800px;
   align-items: center;
    justify-content: center;
    background-color: white;
    flex-direction: column;
  
    width: 350px;
  
   
}

.mensa span{
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
}

