.boximagem{
    margin-top: 100px;
   
   
   

}

.fundo{
    background-color: rgba(16, 13, 13, 0.35);
    margin-top: 120px;
   
    position: fixed;
    margin-left: 35px;
    width: 325px;
    height: 606px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #FFF;
   font-size: 30px;
   font-weight: 800;
   cursor: pointer;
   
    border-radius: 60px;
}


.fotologo img{
    margin-top: 8px;
    position: fixed;
    margin-left: 20px;
    width: 340px;
    height: 600px;
    max-width: 340px;
    max-height: 650px;
   
    border-radius: 60px;
  
 
  
}
   

.boxicons{
    display: flex;
    margin-left: 90px;
   
}


.boxicons img{
    margin-top: 628px;
    margin-left: 20px;
    width: 40px;
    height: auto;
    border-radius: 5px;
}


.fotomoldura img{
    position: fixed;
    margin-left: 20px;
    height: 93%;
    width: 350px;
    height: 625px;
}
.seachbar{
    position: absolute;
    margin-top: 380px;
    margin-left: 40px;
    
}

.boxicons{
    margin-top: -85px;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
   
}
.boxloading{
    margin-top: 400px;
    display: flex;
    align-items: center;
    justify-content: center;  
    flex-direction: column; 
  
}

.icons img{
   

width: 30px;
height: 30px;
padding: 10px;

}

