.cont span{
    margin-top: 20px;
color: black;
font-size: 30px;
}


.cont{
 
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fafafc;
   
}
.boxLoading img{
  border-radius: 10px;
}

.boxLoading h3{
  color: #0e0e0e;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 800;
}
.pt{
  
  display: flex;
margin-top: 10px;
  justify-content: center;
  align-items: center;
  padding: 11px;
  grid-gap: 11px;
}
.pt span{
 
  font-size: 11px;
  font-weight: 600;
}
.blss{
  height: 13px;
  width: 13px;

  border-radius: 11px;
  background-color: rgb(0, 0, 0);
  animation: blss .99s linear infinite;
  box-shadow: 33px #000000;
}
.boxlocalisando span{
  font-weight: bold;
}
.itemsposts span{
  color: #0202026e;
  font-weight: 300;
  font-size: 15px;

}

.itemsposts p{
  color: #0202026e;
  font-weight: 300;
  font-size: 15px;

}
@keyframes blss{

  0% { opacity: 1;}
  50% { opacity: 0.5;}
  100% { opacity: 0;}
}

.smartplayer-video{
  height: 199px;
  border-radius: 19px;
  width: 300px;
  background-color: black;
  margin-bottom: 16px;
}
.bxmages{
 
    padding-top: .8rem;
    padding-bottom: .1rem;
  gap: 1.3rem;
 border-radius: 16px;
  width: 300px;
  background-color: #fafafc;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
  align-items: center;
 
}
.bxmages span{
  color: #ffffff;
  font-size: 19px;
}

.bxmages img{
  border-radius: 50%;
}

.boxLoading{
  display: flex;
  position: absolute;
  margin-top: 280px;
  width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafc;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 20px 20px 0px 0px;
    height: 900px;
    margin-bottom: 10px;
}

.boxcoments img{
  border-radius: 50%;
  margin-top: 10px;
}

.scrool{

  margin-inline: auto;
  max-width: 300px;
  height: 20px;
  background-color: #000000;
  padding: 25px;
  border-radius: 5px;
  overflow: hidden;
  mask: linear-gradient(to right, transparent, white 5%, white 85%, transparent);
  
}

.mages{
  
  flex-direction: row;
  display: flex;
  gap: 10px;
  width: max-content;
  animation: scroolInfinito 30s linear infinite;
  align-items: center;
  justify-content: center;
}

.bx1{
 
  white-space: nowrap;

}

@keyframes scroolInfinito{
   to{
translate: calc(-50% - 5%);
  }
}

.itemsposts{

  display: flex;
  flex-direction: column;
  width: 200px;

}

.itemsposts span{
  color: #0202026e;
}

.boxLoading span{
  margin-top: 20px;

  color: rgb(118, 118, 118);
}


.boxLoading h2{
  background-color: rgba(255, 255, 255, 0.103);
  color: #02020283;
  width: 360px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.boxLoading h1{
    font-size: 20px;
    margin-top: 50px;
}
.boxLoading h3{
  text-align: center;
  
  font-size: inherit;
  font-weight: inherit;
}


#myProgress {
    width: 100%;
    
  }
  
  #myBar {
    width: 0%;
    height: 15px;
    background-color: rgb(6, 208, 57);
    margin-bottom: 10px;
   
    line-height: normal;
  }

  .boxprogresso{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
  

  }

  .boxprogresso span{
    font-size: 20px;
  }

  .boxprogresso.progress{
    padding-left: 10px;
    font-size: 
    20px;
    font-weight: bold;
  }


.boxList{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: last baseline;
   
  
  
    
}
.boxList span{
    font-size: 20px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:  rgba(134, 134, 134, 0.854);;
   
}
.boxLoading img{

  margin-bottom: 10px;
}
.separador{
   background-color: rgba(134, 134, 134, 0.854);
   width: 70%;
   height: 1px;
   margin-bottom: 40px;
   margin-top: 40px;
}

.boxlocalisando{
    margin-top: 50px;
}
.status{
  margin-top: 10px;
  font-weight: 600;
}
.status span{

  font-size: 15px;
  align-items: center;
}