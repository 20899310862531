header {
  background: #000000;
      width: 100%;
      height: 420px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
   

    
     
  
  }
header img{
  margin-top: -10px;
}
 
  
  header .btnLink span{
    
    color: #FFF;
    font-size: 20px;
   
  }
  
  header .logo span{
    margin-right: 20px;
    color: #FFF;
    font-size: 15px;
  }
  
  .boxinfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
  }
  